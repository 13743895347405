<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('order')"
                    id="order-tab"
                    data-toggle="tab"
                    href="#order"
                    role="tab"
                    aria-controls="order"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('list')"
                    id="list-tab"
                    data-toggle="tab"
                    href="#list"
                    role="tab"
                    aria-controls="list"
                    aria-selected="false"
                    >Danh sách VIP</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="order" role="tabpanel" aria-labelledby="order-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Link hoặc ID profile cần cài VIP:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="fb_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link hoặc ID cần tăng"
                                />
                                <div class="card card-orange mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-14 mb-0">
                                            <label class="mb-0 text-danger font-bold"
                                                >Get ID Facebook từ Link nhanh tại
                                                <a
                                                    href="https://findids.net/"
                                                    target="_blank"
                                                    class="bold font-14 mb-0 blue"
                                                    >Findids.net</a
                                                ></label
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-6 col-12">
                                <label class="bold"
                                    >Số lượng <b class="bold">{{ type_text }}</b> nhỏ nhất cần tăng:</label
                                >
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="min_speed"
                                    class="form-control input-light"
                                />
                            </div>
                            <div class="col-md-6 col-12">
                                <label class="bold"
                                    >Số lượng <b class="bold">{{ type_text }}</b> lớn nhất cần tăng:</label
                                >
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="max_speed"
                                    class="form-control input-light"
                                />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-6 col-12">
                                <label class="bold">Số lượng bài viết trong 1 ngày:</label>
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="max_post_daily"
                                    class="form-control input-light"
                                />
                            </div>
                            <div class="col-md-6 col-12">
                                <label class="bold">Số ngày cần mua VIP:</label>
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="days"
                                    class="form-control input-light"
                                />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Bộ lọc từ khóa:</h6>
                            </div>
                            <div class="col-md col-12">
                                <textarea
                                    class="form-control input-light"
                                    rows="3"
                                    v-model="keyword_match"
                                    placeholder="Mỗi từ khóa cách nhau một dấu phẩy"
                                ></textarea>
                                <h6 class="font-bold green font-14 mb-0">
                                    Nếu không có từ khóa, hệ thống sẽ Comment tất cả các bài viết
                                </h6>
                                <h6 class="green font-14">
                                    Chỉ các bài viết có <em>từ khóa trên đây</em> mới tiến hành bình luận,
                                    <b class="font-bold">mỗi từ khóa cách nhau một dấu phẩy</b>
                                </h6>
                            </div>
                        </div>
                        <div v-if="type != 'share_group'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Lọc nâng cao:</h6>
                            </div>
                            <div class="col-md col-12 text-left">
                                <div class="row">
                                    <div class="col-md-6 mt-2">
                                        <button
                                            @click="is_filter = !is_filter"
                                            :class="[
                                                'btn btn-block btn-rounded bold',
                                                is_filter ? 'btn-filter' : 'btn-light'
                                            ]"
                                            type="submit"
                                        >
                                            Bật
                                        </button>
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <button
                                            @click="is_filter = !is_filter"
                                            :class="[
                                                'btn btn-block btn-rounded bold',
                                                !is_filter ? 'btn-filter' : 'btn-light'
                                            ]"
                                            type="submit"
                                        >
                                            Tắt
                                        </button>
                                    </div>
                                </div>
                                <h6 v-show="is_filter" class="mb-0 mt-3 font-14">
                                    Hệ thống sẽ <span class="bold green">thu phí thêm 20%</span> cho
                                    <span class="bold green">1 lựa chọn nâng cao</span>
                                </h6>
                                <div v-show="is_filter" class="card card-gray mt-2">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col-md-4">
                                                <h6 class="bold mb-0">Giới tính:</h6>
                                            </div>
                                            <div class="col-md">
                                                <div class="row">
                                                    <div class="col-md-4 mt-2">
                                                        <button
                                                            @click="setGender('male')"
                                                            :class="[
                                                                'btn btn-block btn-sm btn-rounded bold',
                                                                gender == 'male' ? 'btn-filter' : 'btn-light'
                                                            ]"
                                                            type="submit"
                                                        >
                                                            Nam
                                                        </button>
                                                    </div>
                                                    <div class="col-md-4 mt-2">
                                                        <button
                                                            @click="setGender('female')"
                                                            :class="[
                                                                'btn btn-block btn-sm btn-rounded bold',
                                                                gender == 'female' ? 'btn-filter' : 'btn-light'
                                                            ]"
                                                            type="submit"
                                                        >
                                                            Nữ
                                                        </button>
                                                    </div>
                                                    <div class="col-md-4 mt-2">
                                                        <button
                                                            @click="setGender('')"
                                                            :class="[
                                                                'btn btn-block btn-sm btn-rounded bold',
                                                                gender == '' ? 'btn-filter' : 'btn-light'
                                                            ]"
                                                            type="submit"
                                                        >
                                                            Tất Cả
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-3">
                                            <div class="col-md-4">
                                                <h6 class="bold mb-0">Độ tuổi:</h6>
                                            </div>
                                            <div class="col-md">
                                                <div class="row align-items-center">
                                                    <div class="col-5">
                                                        <div class="input-group">
                                                            <select
                                                                v-model="age.min"
                                                                class="form-control custom-select select-light"
                                                            >
                                                                <option value="">Không lọc theo tuổi</option>
                                                                <option
                                                                    v-for="(item, index) in 65"
                                                                    :key="index"
                                                                    v-show="item > 12"
                                                                    :value="item"
                                                                >
                                                                    {{ item }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <h6 class="bold mb-0">đến</h6>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="input-group">
                                                            <select
                                                                :disabled="!age.min"
                                                                v-model="age.max"
                                                                class="form-control custom-select select-light"
                                                            >
                                                                <option
                                                                    v-for="(item, index) in 65"
                                                                    :key="index"
                                                                    v-show="item > 12"
                                                                    :value="item"
                                                                >
                                                                    {{ item }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-3">
                                            <div class="col-md-4">
                                                <h6 class="bold mb-0">Số bạn bè:</h6>
                                            </div>
                                            <div class="col-md">
                                                <div class="row align-items-center">
                                                    <div class="col-5">
                                                        <div class="input-group">
                                                            <select
                                                                v-model="friend.min"
                                                                class="form-control custom-select select-light"
                                                            >
                                                                <option value="">Không lọc bạn bè</option>
                                                                <option
                                                                    v-for="(item, index) in 50"
                                                                    :key="index"
                                                                    :value="item * 100"
                                                                >
                                                                    {{ item * 100 }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <h6 class="bold mb-0">đến</h6>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="input-group">
                                                            <select
                                                                :disabled="!friend.min"
                                                                v-model="friend.max"
                                                                class="form-control custom-select select-light"
                                                            >
                                                                <option
                                                                    v-for="(item, index) in 50"
                                                                    :key="index"
                                                                    :value="item * 100"
                                                                >
                                                                    {{ item * 100 }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Nội dung cần comment:</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="card card-white">
                                    <div class="card-body">
                                        <h6 class="text-danger font-bold font-14">
                                            Nghiêm cấm bình luận những nội có cử chỉ, lời nói thô bạo, khiêu khích, trêu
                                            ghẹo, xúc phạm nhân phẩm, danh dự của Cá nhân hoặc Tổ chức.
                                        </h6>
                                        <h6 class="text-danger font-bold font-14">
                                            Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và
                                            phải chịu hoàn toàn trách nhiệm trước pháp luật.
                                        </h6>
                                    </div>
                                </div>
                                <div>
                                    <label class="mr-2 mt-3"
                                        >Nhập nội dung bình luận (<label class="font-bold"
                                            >Mỗi dòng tương đương với 1 bình luận</label
                                        >):</label
                                    >
                                    <span class="badge badge-primary text-white py-1">Số lượng: {{ quantity }}</span>
                                    <textarea
                                        class="form-control input-light"
                                        rows="10"
                                        v-model="list_messages"
                                        placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 bình luận"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="card card-orange form-group">
                            <div class="card-body">
                                <p class="bold mt-2 mb-0">
                                    Tổng tiền của gói vip sẽ = (Giá tiền order mỗi
                                    <b class="font-bold">{{ type_text }}</b
                                    >) x (Số lượng <b class="font-bold">{{ type_text }}</b> lớn nhất mỗi bài) x (Số
                                    lượng bài trong ngày) x (Số ngày đăng ký vip)
                                </p>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold">Giá tiền mỗi tương tác:</h6>
                                <h6 class="font-bold mb-0 font-13">
                                    Giá thấp nhất: <span class="bold green">{{ price_default }} {{ currency }}</span>
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="price" type="number" class="form-control input-light" />
                                <h6 class="font-14 mt-3">
                                    Mẹo nhỏ:
                                    <span class="green font-bold"
                                        >Hệ thống ưu tiên chạy các job giá cao trước nên nếu bạn cần gấp bạn có thể set
                                        giá job của mình cao hơn 1 vài đồng để chạy nhanh nhất có thể nhé.</span
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            <span
                                                >Bạn sẽ mua vip
                                                <span class="bold">{{ quantity }} {{ type_text }}/ 1 bài viết</span>
                                                trong <span class="bold">{{ days }} ngày</span> với giá
                                                <span class="bold">{{ priceSingle | formatNumber }}</span>
                                                {{ currency }} / {{ type_text }}.</span
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-red my-3">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Hệ thống chỉ tăng Like cho các bài viết đăng mới, không hỗ trợ các bài viết thêm
                                    hình ảnh vào album đã có sẵn.
                                </p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>
                                    - Hệ thống sử dụng 99% tài khoản người VN, fb thật để tương tác like, comment,
                                    share....
                                </p>
                                <p>
                                    - Hỗ trợ trang cá nhân và fanpage. Khi đăng bài viết mới hệ thống sẽ tự động quét và
                                    tăng tương tác theo yêu cầu của gói
                                </p>
                                <p>
                                    - Tốc độ tăng nhanh chậm ngẫu nhiên tránh bị facebook phát hiện. Không bảo hành số
                                    lượng tương tác, Tự động hoàn tiền số ngày chưa sử dụng nếu bạn hủy vip
                                </p>
                                <p class="mb-0">
                                    - Vui lòng lấy đúng id vip cần tạo và check kỹ vip tránh tạo nhầm, tính năng đang
                                    trong giai đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab">
                <datatable :columns="columnsTableLogs" :rows="list_orders">
                    <th v-if="panel != 'logs'" slot="thead-tr">
                        Thao Tác
                    </th>
                    <td
                        v-if="panel != 'logs'"
                        slot="tbody-tr"
                        slot-scope="props"
                        class="text-center"
                        style="vertical-align: middle;"
                    >
                        <button
                            type="button"
                            :class="['btn btn-sm btn-info mr-2']"
                            @click="editVipAccount(props.row)"
                            data-toggle="modal"
                            href="#edit-vip"
                        >
                            <i class="fa fa-edit"></i>
                            Điều Chỉnh
                        </button>
                        <button
                            @click.stop.prevent="deleteVip(props.row)"
                            type="button"
                            :class="['btn btn-sm btn-danger align-items-center']"
                        >
                            <i class="fa fa-trash"></i>
                            Hủy Vip
                        </button>
                    </td>
                </datatable>
            </div>
        </div>
        <div class="modal fade" id="edit-vip">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Chỉnh Sửa Vip Comment ID:
                            <a
                                :href="'https://wwww.facebook.com/' + accountEdit.fb_id"
                                v-html="accountEdit.fb_id"
                                target="_blank"
                            ></a>
                        </h4>
                    </div>
                    <div class="modal-body">
                        <form ref="form" @submit.stop.prevent="saveEditVip">
                            <div class="form-group col-md-12">
                                <label for="reaction-buff"
                                    >Danh sách các Comment (hệ thống sẽ tăng ngẫu nhiên theo danh sách này)
                                    <label class="font-bold text-danger"
                                        >Mỗi dòng tương đương với 1 Comment:</label
                                    ></label
                                >
                                <textarea
                                    class="form-control"
                                    rows="10"
                                    v-model="accountEdit.list_messages"
                                    :placeholder="accountEdit.cmtPlaceHolder"
                                ></textarea>
                            </div>
                            <div class="form-group col-xl-12">
                                <label>Ghi chú</label>
                                <input
                                    v-model="accountEdit.notes"
                                    type="text"
                                    class="form-control"
                                    placeholder="Nhập ghi chú"
                                />
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Đóng</button>
                        <button type="button" :class="['btn btn-primary']" @click="saveEditVip">Lưu Cài Đặt</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError, formatNumber } from "../../../../helpers"
import {
    orderVipComment,
    convertUsernameToFbId,
    getListVipComment,
    deleteVipComment,
    editVipComment
} from "../../../../api/services/facebook"
import datatable from "../../../../components/datatable/datatable"
export default {
    name: "facebook-order",
    components: {
        datatable
    },
    data() {
        return {
            list_orders: [],
            type: "vip_seeding_like",
            quantity: 1,
            object_id_search: "",
            gender: "",
            fb_id: "",
            age: {
                min: 0,
                max: 0
            },
            friend: {
                min: 0,
                max: 0
            },
            is_filter: false,
            columnsTableLogs: [
                { label: "#", field: "index", index: true },
                { label: "Username", field: "username" },
                // { label: 'Uid', field: 'fb_id', fb_link: true },
                { label: "Name", field: "info_user", html: true },
                { label: "Min", field: "min_comment", numeric: true },
                { label: "Max", field: "max_comment", numeric: true },
                { label: "Posts", field: "max_post_daily", numeric: true },
                { label: "Giá", field: "price_per", numeric: true },
                { label: "Tổng Tiền", field: "prices", numeric: true },
                // { label: 'Loại', field: 'type', html: true },
                { label: "Khởi Tạo", field: "created_at", timeago: true },
                { label: "Hết Hạn", field: "time_expired", timeago: true },
                { label: "Nội dung", field: "list_messages_html", textarea: true },
                { label: "Ghi chú", field: "notes", textarea: true }
            ],
            price: 0,
            rows: [],
            days: 7,
            max_post_daily: 2,
            min_like: 50,
            max_like: 50,
            list_messages: [],
            notes: "",
            total_post: 10,
            panel: "",
            reNewOrder: {},
            price_default: 0,
            min_speed: 2,
            max_speed: 5,
            keyword_match: "",
            accountEdit: {}
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 70)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            return Math.ceil(price)
        },
        totalPrice() {
            return (
                this.priceSingle *
                (this.quantity > this.max_speed ? this.quantity : this.max_speed) *
                this.max_post_daily *
                this.days
            )
        },
        type_text() {
            return this.type.replace("vip_seeding_", "")
        },
        router() {
            return this.$route.path
        }
    },
    watch: {
        async fb_id() {
            if (this.fb_id.indexOf("facebook.com") > -1) {
                this.fb_id = await this.getFbId(this.fb_id)
            }
        },
        list_messages(value) {
            this.quantity = value ? value.match(/.+/g).length : 0
        }
    },
    created() {
        this.type = "vip_seeding_comment"
        let prices = this.prices
        let price = prices.find(price => price.package_name === this.type)
        if (price) {
            this.price_default = price.price
            this.price = price.price
        }
    },
    methods: {
        editVipAccount: async function(account) {
            this.accountEdit = account
            this.accountEdit.list_messages =
                account.text_comment && account.text_comment.length > 2
                    ? account.text_comment.split(",").join("\n")
                    : account.text_comment
        },
        saveEditVip: async function() {
            let postData = {
                list_messages: this.accountEdit.list_messages.match(/.+/g),
                notes: this.accountEdit.notes
            }
            let data = await editVipComment(this.accountEdit.fb_id, postData)
            if (data.status === 200 && data.success) {
                // eslint-disable-next-line no-undef
                $("#edit-vip").modal("hide")
                this.$swal.fire("Thành công", data.message, "success")
            } else {
                this.$swal.fire("Lỗi", catchError(data), "error")
            }
        },
        order: async function() {
            if (this.list_messages.length < 1) {
                this.$swal.fire("Lỗi", "Bạn chưa nhập nội dung cần Comment", "error")
                return
            }
            let checkBackList = this.list_messages
                .toLowerCase()
                .match(
                    /dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g
                )
            if (this.list_messages.length > 1 && checkBackList && checkBackList.length > 0) {
                this.$swal(
                    "Lỗi",
                    `${this.type === "comment" ? "Bình luận" : "Review"} của bạn có chứa nội dung [${
                        checkBackList[0]
                    }] vi phạm tiêu chuẩn cộng đồng, hãy chỉnh sửa lại nội dung cần bình luận!`,
                    "error"
                )
                return
            }
            if (this.price < this.price_default) {
                this.$swal.fire("Lỗi", "Giá cài đặt phải lớn hơn giá tối thiểu", "error")
                return
            }
            if (this.fb_id.length < 5) {
                this.$swal.fire("Lỗi", "Vui lòng nhập Id cần cài VIP", "error")
                return
            }
            if (parseInt(this.min_speed) > parseInt(this.max_speed)) {
                this.$swal.fire("Lỗi", "Min comment phải nhỏ hơn max comment", "error")
                return
            }
            let check = await this.$swal.fire({
                title: "Xác nhận?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                reverseButtons: true,
                html: `Bạn có chắc chắn muốn đăng ký gói VIP ${this.type_text}:<br>- Thời gian ${
                    this.days
                } ngày <br> - ${this.max_post_daily} bài viết / ngày <br> - Với giá ${this.price} ${this.currency} / ${
                    this.type_text
                } <br> - Ngẫu nhiên từ ${this.min_speed} - ${this.max_speed} ${
                    this.type_text
                } / bài viết<br>=> Tổng tiền VIP ${this.type_text}: <b> ${formatNumber(this.totalPrice)}</b> ${
                    this.currency
                }.`
            })
            if (!check.value) {
                return
            }
            let postData = {
                fb_id: this.fb_id,
                days: this.days,
                type: this.type_text,
                price_per: this.price,
                min_speed: this.min_speed,
                max_speed: this.max_speed,
                quantity: this.max_speed,
                notes: this.notes,
                max_post_daily: this.max_post_daily,
                keyword_match: this.keyword_match
            }
            if (this.type_text === "review" || this.type_text === "comment") {
                postData.list_messages = this.list_messages.match(/.+/g)
            }
            let data = await orderVipComment(postData)
            if (data.status === 200 && data.success) {
                this.$swal.fire("Thành công", "Tạo gói Vip Comment thành công !", "success")
            } else {
                this.$swal.fire("Lỗi", catchError(data), "error")
            }
        },
        deleteVip: async function(vip) {
            let check = await this.$swal({
                title: "Xác nhận?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                html: `Bạn có chắc chắn muốn hủy gói VIP này? <br> Bạn sẽ được hoàn lại số tiền những ngày vip chưa sử dụng và bị trừ thêm 10.000 ${this.currency} tiền phí hủy để tránh spam`
            })
            if (!check.value) {
                return
            }
            let postData = {
                id: vip.id
            }
            let data = await deleteVipComment(postData)
            if (data.status === 200 && data.success) {
                let index = this.list_orders.indexOf(vip)
                this.list_orders.splice(index, 1)
                this.$swal("Thông tin", "Bạn đã hủy vip thành công", "success")
            } else {
                this.$swal("Thông tin", data.message || "Bạn đã hủy vip thất bại", "error")
            }
        },
        getListVipSeedingOrder: async function() {
            let data = await getListVipComment()
            if (data.data) {
                this.list_orders = data.data.map(order => {
                    order.info_user = `<img src="https://graph.facebook.com/${order.fb_id}/picture?width=100&height=100" width="36" alt="user${order.fb_id}" class="active rounded-circle">`
                    order.info_user += `<a href="https://wwww.facebook.com/${order.fb_id}" target="_blank" class="font-bold text-info ml-2">${order.fb_name}</a>`
                    // order.time_expired_html = moment(order.time_expired).fromNow()
                    // order.target = order.gender ? `<span class="badge badge-info">${order.gender}</span>` : ''
                    // order.target += order.age_min ? `<span class="badge badge-success ml-1">${order.age_min} - ${order.age_max}</span>` : ''
                    // order.target += order.friend_min ? `<span class="badge badge-warning text-white ml-1">${order.friend_min} - ${order.friend_max}</span>` : ''
                    // order.type_html = order.type.toUpperCase()
                    order.list_messages_html = order.text_comment
                    // order.status_html = order.is_refund ? '<span class="badge badge-warning">Hoàn tiền</span>' : (order.count_is_run >= order.quantity ? '<span class="badge badge-success">Hoàn thành</span>' : '<span class="badge text-white badge-primary">Đang chạy</span>') + `<span class="badge badge-success ml-1">${order.count_success}</span><span class="badge badge-danger ml-1">${order.count_error}</span>`
                    return order
                })
            }
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
            if (panel === "list") {
                this.list_orders = []
                await this.getListVipSeedingOrder()
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        getFbId: async function(link) {
            if (link.match(/facebook/)) {
                this.$toastr.success("Hệ thống đang tự động get id cho link facebook bạn nhập vào!")
                if (link.indexOf("profile.php") > -1) {
                    var regex = (link + "&").match(/id=(\d*?)&/)
                    if (regex.length === 2) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        return regex[1]
                    } else {
                        return false
                    }
                } else {
                    var username = link.replace(
                        /https|http|\/|\\|www\.|mbasic\.|m\.facebook.com|facebook\.com|:|\?.*/gm,
                        ""
                    )
                    let data = await convertUsernameToFbId(username)
                    if (data.status === 200 && data.success) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        return data.data.id
                    } else {
                        return false
                    }
                }
            } else return true
        }
    }
}
</script>
